import { combineReducers } from 'redux';
import bonusReducer, { BonusState } from './features/bonus/bonusSlice';
import clientsReducer, { ClientState } from './features/clients/clientsSlice';
import freezerReducer, { FreezersState } from './features/freezer/freezerSlice';
import pointOfSalesReducer, { PointOfSalesState } from './features/pointOfSales/pointOfSalesSlice';
import productsReducer, { ProductsState } from './features/products/productsSlice';
import ticketReducer, { TicketsState } from './features/ticket/ticketSlice';

export interface GlobalState {
    clients: ClientState,
    pos: PointOfSalesState,
    tickets: TicketsState,
    freezers: FreezersState,
    bonus: BonusState,
    products: ProductsState
}

export const rootReducer = combineReducers({
    clients: clientsReducer,
    pos: pointOfSalesReducer,
    tickets: ticketReducer,
    freezers: freezerReducer,
    bonus: bonusReducer,
    products: productsReducer
});