import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import ReactPlayer from "react-player/lazy";
import "./helpUser.css";

import ButtonBase from "@mui/material/ButtonBase";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item>
            <Paper
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >

            

              <div>

                <div>
                  <Grid container spacing={2}>
                    <Grid item>
                      <ReactPlayer
                        className="react-player containerPlay"
                        url="https://hisoporte.s3.amazonaws.com/videos/od2/Subir-Facturas-Poveedores.mp4"
                        width="100%"
                        height="100%"
                        controls={true}
                      /> 
                      
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <h5>VIDEO TUTORIAL MODULO COMPRAS/ENVÍO DE DOCUMENTOS</h5>
                          <h6>
                            Este video muestra de manera concreta y sencilla la
                            forma en la cual se deben de enviar los documentos (FACTURAS CDFI)  
                            en el módulo correspondiente.
                          </h6>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>




              

            </Paper>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <div className="ModulosHelp">
              
              <h5 >MÓDULO COMPRAS/ENVÍO DE DOCUMENTOS</h5>
              </div>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
