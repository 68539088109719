import { IClient } from "../../../interfaces";
import { sortDataByFields } from "../../../utils/sortBy";
import { ClientsActionTypes } from "./actionTypes";

export interface ClientState {
    clients: IClient[],
    isLoading: boolean,
    filters: any,
}

const initialState: ClientState = {
    clients: [],
    isLoading: false,
    filters: {},
};

export default function clientsSlice (state = initialState, action: any) {
    switch (action.type) {
        case ClientsActionTypes.SET_CLIENTS:
            action.payload.pop(); // if grand total is not in the array delete this line
            return {
                ...state,
                clients: action.payload,
                isLoading: false
            }
        case ClientsActionTypes.LOAD_CLIENTS:
            return {
                ...state,
                isLoading: true
            }
        case ClientsActionTypes.FILTER_CLIENTS_BY_VALUE:

            if (action.value.value === '' ||
                action.value.value === undefined ||
                action.value.value === null) {
                
                delete state.filters[action.value.key];

                return {
                    ...state,
                    filters: {...state.filters} 
                }
            } else {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.value.key]: action.value.value
                    }
                }
            }
        case ClientsActionTypes.SORT_CLIENTS_BY_VALUE:
            return {
                ...state,
                clients: state.clients.sort(sortDataByFields(action.value))
            }
        case ClientsActionTypes.RESET_CLIENTS:
            return {...initialState}
        default:
            return state
    }
}