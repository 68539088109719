import { IFreezer } from "../../../interfaces";
import { FreezerActionTypes } from "./actionTypes";

export interface FreezersState {
    freezers: IFreezer[],
    isLoading: boolean,
}

const initialState: FreezersState = {
    freezers: [],
    isLoading: false
};

export default function freezerSlice (state = initialState, action: any) {
    switch (action.type) {
        case FreezerActionTypes.SET_FREEZERS:
            return {
                ...state,
                freezers: action.payload,
                isLoading: false
            }
        case FreezerActionTypes.LOADING_FREEZERS:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}