import { IProducts } from "../../../interfaces";
import { sortDataByFields } from "../../../utils/sortBy";
import { ProductsActionTypes } from "./actionTypes";

export interface ProductsState {
    products: IProducts[],
    childProducts: IProducts[],
    isLoading: boolean,
    isChildLoading: boolean,
    filters: any
}

const initialState: ProductsState = {
    products: [],
    childProducts: [],
    isLoading: false,
    isChildLoading: false,
    filters: {}
};

export default function productsSlice (state = initialState, action: any) {
    switch (action.type) {
        case ProductsActionTypes.SET_PRODUCTS:
            action.payload.pop(); // if grand total is not in the array delete this line
            return {
                ...state,
                products: action.payload,
                isLoading: false
            }
        case ProductsActionTypes.LOADING_PRODUCTS:
            return {
                ...state,
                isLoading: true
            }
        case ProductsActionTypes.SET_CHILD_PRODUCTS:
            return {
                ...state,
                childProducts: action.payload,
                isChildLoading: false
            }
        case ProductsActionTypes.LOADING_CHILD_PRODUCTS:
            return {
                ...state,
                isChildLoading: true
            }
        case ProductsActionTypes.FILTER_PRODUCTS_BY_VALUE:

            if (action.value.value === '' ||
                action.value.value === undefined ||
                action.value.value === null) {
                
                delete state.filters[action.value.key];

                return {
                    ...state,
                    filters: {...state.filters} 
                }
            } else {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.value.key]: action.value.value
                    }
                }
            }
        case ProductsActionTypes.SORT_PRODUCTS_BY_VALUE:
            return {
                ...state,
                products: state.products.sort(sortDataByFields(action.value))
            }
        default:
            return state
    }
}