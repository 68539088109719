import { IPointOfSale, IPointOfSaleDetails } from "../../../interfaces";
import { PointOfSalesActionTypes } from "./actionTypes";

export interface PointOfSalesState {
    pos: IPointOfSale[],
    singlePos: IPointOfSaleDetails[],
    isLoading: boolean,
}

const initialState: PointOfSalesState = {
    pos: [],
    singlePos: [],
    isLoading: false
};

export default function pointOfSalesSlice (state = initialState, action: any) {
    switch (action.type) {
        case PointOfSalesActionTypes.SET_POINT_OF_SALES:
            return {
                ...state,
                pos: action.payload,
                isLoading: false
            }
        case PointOfSalesActionTypes.SET_POINT_OF_SALE:
            return {
                ...state,
                singlePos: action.payload,
                isLoading: false
            }
        case PointOfSalesActionTypes.LOADING_POINT_OF_SALES:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}