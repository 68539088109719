import { IBonus } from "../../../interfaces";
import { sortDataByFields } from "../../../utils/sortBy";
import { BonusActionTypes } from "./actionTypes";

export interface BonusState {
    bonus: IBonus[],
    isLoading: boolean,
    filters: any
}

const initialState: BonusState = {
    bonus: [],
    isLoading: false,
    filters: {}
};

export default function bonusSlice (state = initialState, action: any) {
    switch (action.type) {
        case BonusActionTypes.SET_BONUS:
            action.payload.pop(); // if grand total is not in the array delete this line
            return {
                ...state,
                bonus: action.payload,
                isLoading: false
            }
        case BonusActionTypes.LOAD_BONUS:
            return {
                ...state,
                isLoading: true
            }
        case BonusActionTypes.FILTER_BONUS_BY_VALUE:

            if (action.value.value === '' ||
                action.value.value === undefined ||
                action.value.value === null) {
                
                delete state.filters[action.value.key];

                return {
                    ...state,
                    filters: {...state.filters} 
                }
            } else {
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        [action.value.key]: action.value.value
                    }
                }
            }
        case BonusActionTypes.SORT_BONUS_BY_VALUE:
            return {
                ...state,
                bonus: state.bonus.sort(sortDataByFields(action.value))
            }
        default:
            return state
    }
}