export function sortDataByFields(field) {
    let dir = 1;
    if (field[0] === '-') {
        dir = -1;
        field = field.substring(1);
    }
    return function (a, b) {

        if (typeof a[field] === 'number' || typeof b[field] === 'number') {
            if (Number(a[field]) > Number(b[field])) return -(dir);
            if (Number(a[field]) < Number(b[field])) return dir;
        }

        if (a[field] > b[field]) return dir;
        if (a[field] < b[field]) return -(dir);
        return 0;
        
    }
};